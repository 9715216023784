<template>
  <div class="setRange">
    <div class="filter-box">
      <div class="fitler-item">
        学段：
        <el-select
          v-model="searchItems.level"
          placeholder="请选择"
          @change="changeLevel()"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="fitler-item">
        入学年份：
        <el-select
          v-model="searchItems.year"
          placeholder="请选择"
          @change="changeGrade()"
        >
          <el-option
            v-for="item in yearList"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="fitler-item">
        学科：
        <el-select
          v-model="searchItems.subjectId"
          :collapse-tags="true"
          placeholder="请选择"
          @change="handleClick()"
        >
          <el-option
            v-for="item in examsubjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="fitler-item">
        选择日期：
        <el-date-picker
          v-model="time"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <el-button type="primary">确定</el-button>
    </div>
    <div style="margin: 18px 0">任务范围</div>
    <div class="check-item">
      <div class="class-item">
        <div class="class-header">
          <div>班级</div>
          <div>任务数</div>
        </div>
        <div class="class-task-box edit-scroll-style">
          <div
            v-for="(item, index) in classList"
            :key="index"
            class="class-task-item"
            :class="{ 'class-on': item.classId == indexClassId }"
            @click="checkClass(item)"
          >
            <div class="class-num">
              {{ item.classNum }}
            </div>
            <div>
              <span style="margin-right: 8px">任务数：1233</span>
              <span>总提数：1233</span>
            </div>
          </div>
        </div>
      </div>
      <template v-for="(aItem, aIndex) in classList">
        <div
          v-if="aItem.classId == indexClassId"
          :key="aIndex"
          class="data-box"
        >
          <div class="check-type-box">
            <div
              v-for="(item, index) in typeList"
              :key="index"
              :class="{ 'check-type-item': item.value == checkDataType }"
              @click="checkDataType = item.value"
            >
              {{ item.label }}
            </div>
          </div>
          <div class="ckeck-value">
            <el-checkbox v-model="checkAll" :indeterminate="true">
              全选
            </el-checkbox>
            待对接/待对接1
          </div>
          <div class="data-item-box edit-scroll-style">
            <template v-if="checkDataType == 1">
              <div v-for="(item, index) in 20" :key="index" class="data-item">
                <el-checkbox-group v-model="aItem.examIds">
                  <el-checkbox :label="item">
                    新世纪光华学校 2024-2025 学年度（上） 第一阶段质量检测高三
                  </el-checkbox>
                </el-checkbox-group>
                题数：100
              </div>
            </template>
            <template v-if="checkDataType == 2">
              <div v-for="(item, index) in 20" :key="index" class="data-item">
                <el-checkbox-group v-model="aItem.hwIds">
                  <el-checkbox :label="item">
                    新世纪光华学校 2024-2025 学年度（上） 第一阶段质量检测高三
                  </el-checkbox>
                </el-checkbox-group>
                题数：100
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div class="btn-box">
      <el-button type="primary" @click="goNext()">下一步</el-button>
    </div>
  </div>
</template>
<script>
const typeList = [
  { value: 1, label: "考试" },
  { value: 2, label: "作业" },
];
let classData = {
  classNum: "1",
  examIds: [],
  hwIds: [],
  studentList: [],
  studentIds: [],
};

import { getSubjectList, getYear } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";

export default {
  name: "SetRange",
  data() {
    return {
      typeList: typeList,
      checkList: [],
      checkDataType: 1,
      checkAll: false,
      classList: [],
      indexClassId: 0,
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      examsubjectOptions: getSubjectList(),
      yearList: getYear(),
      levelOptions: levelOptions(),
      time: [],
      searchItems: {
        level: "",
        year: "",
        subjectId: "",
      },
    };
  },
  created() {
    for (let i = 0; i < 10; i++) {
      let data = classData;
      data.classNum = i + 1 + "班";
      data.classId = i + 1;
      data.showList = false;
      data.checkAll = false;
      data.indeterminate = false;

      data = JSON.parse(JSON.stringify(data));
      for (let j = 0; j < 5; j++) {
        let cData = {
          id: j + "-" + i,
          name: "测试" + j,
        };
        data.studentList.push(cData);
      }
      this.classList.push(data);
    }
    this.indexClassId = this.classList[0].classId;
  },
  methods: {
    checkClass(item) {
      this.indexClassId = item.classId;
    },
    goNext() {
      let data = JSON.parse(JSON.stringify(this.classList));
      // console.log(data);
      this.$parent.goNext(data);
    },
  },
};
</script>
<style lang="scss" scoped>
.setRange {
  padding: 18px;
  background-color: #ffffff;
  .btn-box {
    text-align: center;
    margin-top: 18px;
  }
  .ckeck-value {
    .el-checkbox {
      margin: 18px 18px 18px 0;
    }
  }
  .check-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    .data-item-box {
      max-height: 366px;
      .data-item {
        display: flex;
        color: #0a1119;
        margin-bottom: 18px;
        &:last-child {
          margin-bottom: 0;
        }
        .el-checkbox {
          min-width: 470px;
          color: #0a1119;
          margin-bottom: 0;
        }
      }
    }
    .data-box {
      padding: 18px;
      // background: #fafbfd;
      border: 1px solid #e6eaef;
      width: 100%;

      .check-type-box {
        display: flex;
        > div {
          width: 88px;
          height: 32px;
          background: #ffffff;
          border: 1px solid #dee0e7;
          text-align: center;
          line-height: 32px;
          cursor: pointer;
          &:hover {
            color: #2474ed;
          }
          &:first-child {
            border-radius: 4px 0px 0px 4px;
            border-right: 0;
          }
          &:last-child {
            border-radius: 0px 4px 4px 0px;
            border-left: 1px solid #2474ed;
          }
        }
        .check-type-item {
          border-color: #2474ed;
          background: #f2f8ff;
          color: #2474ed;
        }
      }
    }
    .class-task-box {
      padding: 12px 0;
      max-height: 440px;

      .class-task-item {
        padding: 14px 18px;
        position: relative;
        cursor: pointer;
        transition: 0.2s linear;
        display: flex;
        justify-content: space-between;
        .class-num {
          width: 70px;
          overflow: hidden; /* 隐藏溢出的内容 */
          text-overflow: ellipsis; /* 溢出时显示省略号 */
          white-space: nowrap; /* 强制单行显示 */
        }
      }
      .class-task-item:hover {
        color: #1680ec;
      }
      .class-on {
        background: #f2f8ff;
        color: #1680ec;
        &::after {
          content: "";
          width: 2px;
          height: 49px;
          position: absolute;
          left: 0;
          top: 0;
          background-color: #1680ec;
        }
      }
    }
    .class-item {
      width: 330px;
      border: 1px solid #e6eaef;
      flex-shrink: 0;
      margin-right: 18px;
      .class-header {
        background: #fafbfd;
        border-bottom: 1px solid #e6eaef;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 18px;
        line-height: 1;
      }
    }
  }
  .el-select {
    width: 100px;
  }
  .filter-box {
    display: flex;
    align-items: center;
  }
  .fitler-item {
    margin-right: 12px;
  }
  ::v-deep {
    .el-date-editor .el-range__icon {
      height: 32px;
    }
  }
}
</style>

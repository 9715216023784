<template>
  <div class="parameteRule">
    <div class="parameteRule-left">
      <div class="student-msg">
        1----2个学生无错题，不会生成错题本！
        <el-button type="text">查看</el-button>
      </div>
      <div class="class-num-box">
        <div class="class-num-title">学生列表</div>
        <div class="class-list-box">
          <div
            v-for="(item, index) in classList"
            :key="index"
            class="class-list"
          >
            <div class="class-list-header">
              <el-checkbox
                v-model="item.checkAll"
                :indeterminate="item.indeterminate"
                @change="changeItem(item)"
              >
                {{ item.classNum }}
              </el-checkbox>
              <i
                class="el-icon-caret-bottom"
                :class="{ 'icon-tran': item.showList }"
                @click="item.showList = !item.showList"
              ></i>
            </div>

            <el-checkbox-group
              v-show="item.showList"
              v-model="item.studentIds"
              @change="setStudent(item)"
            >
              <div
                v-for="(bItem, bIndex) in item.studentList"
                :key="bIndex"
                class="student-item"
              >
                <el-checkbox :label="bItem.id">{{ null }}</el-checkbox>
                <span
                  class="student-name"
                  :class="{ 'student-on': bItem.id == indexStudent.id }"
                  @click="checkStudent(bItem)"
                  >{{ bItem.name }}</span
                >
              </div>
            </el-checkbox-group>
          </div>
        </div>
      </div>
      <div class="question-box">
        {{ indexStudent.name }}的错题：888888
        <div class="question-item-box">
          <div v-for="(item, index) in 10" :key="index" class="question-item">
            <span class="question-num">{{ index + 1 }}</span>
            <div class="question-html">
              <div class="pt1">
                <span class="jyw-question-tips">（2024秋•顺义区校级月考）</span
                >已知a∈R，则“|a|≥1”是“
                <div class="MathJye" mathtag="math">
                  |a+
                  <table
                    cellpadding="-1"
                    cellspacing="-1"
                    style="margin-right: 1px"
                  >
                    <tbody>
                      <tr>
                        <td
                          style="
                            border-bottom: 1px solid black;
                            padding-bottom: 1px;
                            font-size: 90%;
                          "
                        >
                          1
                        </td>
                      </tr>
                      <tr>
                        <td style="padding-top: 1px; font-size: 90%">a</td>
                      </tr>
                    </tbody>
                  </table>
                  |≥2
                </div>
              </div>
              <div class="pt2">
                <table style="width: 100%" class="ques quesborder">
                  <tbody>
                    <tr>
                      <td style="width: 48%" class="selectoption">
                        <label class="">A．充分不必要条件</label>
                      </td>
                      <td style="width: 48%" class="selectoption">
                        <label class="">B．必要不充分条件</label>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 48%" class="selectoption">
                        <label class="">C．充要条件</label>
                      </td>
                      <td style="width: 48%" class="selectoption">
                        <label class="">D．既不充分也不必要条件</label>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="question-btn">
              <div class="quesiton-left">
                <span>个人得分率：10%</span>
                <span>2015-5-20</span>
                <span>测试测试001</span>
              </div>
              <div>
                <el-button type="text">答案解析</el-button>
                <el-button type="text">变试题</el-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="parameteRule-right edit-scroll-style">
      <div class="paramete-box">
        <div class="paramete-header">错题生成规则</div>
        <div class="paramete-cnt">
          <div class="paramete-item">错题定义：</div>
          <div class="paramete-item">
            得分率
            <el-input
              v-model="setting.scoringAverageMin"
              style="width: 50px"
              placeholder=""
            ></el-input>
            %至
            <el-input
              v-model="setting.scoringAverageMax"
              style="width: 50px"
              placeholder=""
            >
            </el-input>
            %
          </div>
          <div class="paramete-item">推题方式：</div>

          <div class="paramete-item">
            <el-radio-group v-model="setting.pushQuestionMode">
              <el-radio :label="1">原题</el-radio>
              <el-radio :label="2">变式题+原题</el-radio>
              <el-radio :label="3">变式题</el-radio>
            </el-radio-group>
          </div>
          <div class="paramete-item">最大错题量：</div>
          <div class="paramete-item">
            <el-input
              v-model="setting.errorQuestionMax"
              style="width: 50px"
              placeholder=""
            ></el-input>
            题
            <el-tooltip class="item" effect="dark" placement="top">
              <div slot="content">
                默认按每次任务5题计算最大题量，可手动修 <br />
                改，最多100题当学生错题数超过最大体量 <br />
                时，将按最近发展区原则，为学生选择最适合 <br />
                的错题
              </div>
              <img src="@/assets/icon-img/question.png" alt="" />
            </el-tooltip>
          </div>
          <div class="paramete-item">题目顺序：</div>
          <div class="paramete-item">
            <el-radio-group v-model="setting.questionSort">
              <div class="el-radio-item">
                <el-radio :label="1">按题目难易程度</el-radio>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    根据题目年级得分率判断难易程度，先易后难
                  </div>
                  <img src="@/assets/icon-img/question.png" alt="" />
                </el-tooltip>
              </div>
              <div class="el-radio-item">
                <el-radio :label="2">按时间和题号顺序</el-radio>
                <el-tooltip class="item" effect="dark" placement="top">
                  <div slot="content">
                    根据题目所在考试/作业时间和题号正序显示
                  </div>
                  <img src="@/assets/icon-img/question.png" alt="" />
                </el-tooltip>
              </div>
            </el-radio-group>
          </div>
          <div class="paramete-item">内容展示：</div>
          <div class="paramete-item checkbox-group-box">
            <el-checkbox-group v-model="setting.paperContent">
              <el-checkbox :label="1">原卷信息</el-checkbox>
              <el-checkbox :label="2">个人得分率</el-checkbox>
              <el-checkbox :label="3">知识点信息</el-checkbox>
              <el-checkbox :label="4">教材章节</el-checkbox>
              <el-checkbox :label="5">优秀作答</el-checkbox>
              <el-checkbox :label="6">错题分析</el-checkbox>
            </el-checkbox-group>
          </div>
          <div class="paramete-item">解答题答题区域：</div>
          <div class="paramete-item">
            <el-input
              v-model="setting.errorQuestionMax"
              style="width: 50px"
              placeholder=""
            ></el-input>
            行
          </div>
          <div class="paramete-item" style="margin-bottom: 0">
            <el-button type="primary" plain>更新错题数据</el-button>
          </div>
        </div>
        <div class="paramete-bottom">
          <el-button type="primary">生成个性化错题本（20人）</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ParameteRule",
  data() {
    return {
      classList: [],
      indexStudent: {},
      setting: {
        // 得分率
        scoringAverageMin: 0,
        scoringAverageMax: 70,
        // 推题方式 1 原题 2变式题+原题 3 变式题
        pushQuestionMode: 1,
        // 最大错题量
        errorQuestionMax: 20,
        // 题目顺序 1 按题目难易程度 2 按时间和题号顺序
        questionSort: 1,
        //1原卷信息  2个人得分率  3知识点信息 4教材章节 5优秀作答 6错题分析
        paperContent: [],
        // 解答题作答区域  行
        subjectiveRegion: 3,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkStudent(item) {
      this.indexStudent = JSON.parse(JSON.stringify(item));
      // this.studentId = item.id;
    },
    setStudent(item) {
      if (item.studentIds.length == 0) {
        item.indeterminate = false;
        item.checkAll = false;
        return;
      }
      if (item.studentIds.length != item.studentList) {
        item.checkAll = false;
        item.indeterminate = true;
      }
      if (item.studentIds.length == item.studentList) {
        item.checkAll = true;
        item.indeterminate = false;
      }
    },
    changeItem(item) {
      item.indeterminate = false;
      if (item.checkAll) {
        item.studentIds = item.studentList.map((item) => item.id);
      }
      if (!item.checkAll) {
        item.studentIds = [];
      }
    },
    init() {
      this.classList = this.$parent.classList;
    },
  },
};
</script>
<style lang="scss" scoped>
.parameteRule {
  margin-top: 18px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  .paramete-box {
    border: 1px solid #e6eaef;
    background: #ffffff;
    .paramete-bottom {
      padding: 18px;
      border-top: 1px solid #e6eaef;
      .el-button {
        width: 100%;
      }
    }
    .paramete-header {
      color: #0a1119;
      padding: 10px 18px;
      background: #fafbfd;
      border-bottom: 1px solid #e6eaef;
    }
    .paramete-cnt {
      padding: 18px;

      .paramete-item.checkbox-group-box {
        margin-bottom: 0;
        .el-checkbox-group {
          display: flex;
          justify-content: space-between;
          align-items: center;
          flex-wrap: wrap;
          .el-checkbox {
            width: 50%;
            margin-right: 0;
            margin-bottom: 18px;
          }
        }
      }
      .paramete-item {
        margin-bottom: 18px;
        img {
          vertical-align: text-bottom;
        }
        .el-radio-group {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          .el-radio-item {
            width: 100%;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
            .el-radio {
              display: flex;
              align-items: center;
            }
            img {
              margin-left: 8px;
            }
            &:last-child {
              margin-bottom: 0;
            }
          }
          .el-radio {
            margin-bottom: 0;
            margin-right: 0;
          }
        }
      }
    }
  }
  .question-item-box {
    .question-item {
      position: relative;
      margin-top: 18px;
      border: 1px solid #e6eaef;
      padding-top: 29px;
      .question-btn {
        display: flex;
        justify-content: space-between;
        background: #fafbfd;
        padding: 8px 10px;
        border-top: 1px solid #e6eaef;
        .quesiton-left {
          color: #0a1119;
          display: flex;
          align-items: center;
          span {
            margin-right: 18px;
            &:last-child {
              display: inline-block;
              margin-right: 0;
              max-width: 190px;
              overflow: hidden; /* 隐藏溢出的内容 */
              text-overflow: ellipsis; /* 溢出时显示省略号 */
              white-space: nowrap; /* 强制单行显示 */
            }
          }
        }
      }
      .question-num {
        width: 34px;
        height: 24px;
        background: #fafbfd;
        border: 1px solid #e6eaef;
        position: absolute;
        left: 0;
        top: 0;
        text-align: center;
        line-height: 24px;
      }
    }
  }
  .question-box {
    width: calc(100% - 218px);
  }
  .class-list-box {
    padding: 18px;
    .el-checkbox-group {
      transition: 0.2s linear;
      .el-checkbox {
        display: block;
        margin-right: 0;
        padding-left: 25px;
      }
    }
    .el-checkbox {
      margin-bottom: 18px;
    }
    .student-name {
      cursor: pointer;
    }
    .student-on {
      color: #2474ed;
    }
    .student-item {
      display: flex;
      font-size: 14px;
    }
    .class-list-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .el-icon-caret-bottom {
      margin-bottom: 18px;
      color: #afb4ba;
      font-size: 16px;
      cursor: pointer;
      transition: 0.2s linear;
    }
    .icon-tran {
      transform: rotate(180deg);
    }
  }
  .parameteRule-right {
    // padding: 18px;
    // background: #ffffff;
    width: 300px;
    flex-shrink: 0;
    position: sticky;
    top: 82px;
    right: 0;
    height: calc(100vh - 100px);
    min-height: 300px;
  }
  .parameteRule-left {
    background: #ffffff;
    padding: 18px;
    width: 100%;
    margin-right: 18px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: flex-start;
    .class-num-title {
      background: #fafbfd;
      padding: 10px 18px;
    }
    .class-num-box {
      border: 1px solid #e6eaef;
      width: 200px;
      margin-right: 18px;
      flex-shrink: 0;
    }
    .student-msg {
      background: #f2f8ff;
      padding: 10px;
      line-height: 1;
      margin-bottom: 18px;
      width: 100%;
    }
  }
}
</style>

<template>
  <div class="errorQuestionSetting">
    <div class="header-box">
      <div class="go-back">
        <img src="@/assets/newExam/back.svg" alt="" />
        返回
      </div>
      <div class="step-box">
        <div class="step-item" :class="{ 'step-on': step == 1 }">
          <div class="step-num">1</div>
          <div class="step-name">设置范围</div>
        </div>
        <div class="line-box" :class="{ 'line-on': step == 2 }"></div>
        <div class="step-item" :class="{ 'step-on': step == 2 }">
          <div class="step-num">2</div>
          <div class="step-name">设置范围</div>
        </div>
      </div>
      <div></div>
    </div>
    <setRange v-show="step == 1" ref="setRange"></setRange>
    <parameteRule v-show="step == 2" ref="parameteRule"></parameteRule>
  </div>
</template>
<script>
import setRange from "./components/setRange.vue";
import parameteRule from "./components/parameteRule.vue";
export default {
  name: "ErrorQuestionSetting",
  components: {
    setRange,
    parameteRule,
  },
  data() {
    return {
      step: 1,
    };
  },
  created() {},
  methods: {
    goNext(data) {
      this.step = 2;
      this.classList = data;
      this.$refs.parameteRule.init();
    },
    goBack() {
      this.$router.push({
        path: "/newAnaly/notebookItem",
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.errorQuestionSetting {
  width: 1220px;
  margin: 24px auto;
  .go-back {
    cursor: pointer;
  }
  .header-box {
    padding: 14px 18px;
    background-color: #ffffff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
  }
  .step-box {
    display: flex;
    align-items: center;
    .line-box {
      width: 124px;
      height: 1px;
      background: #e4e4e4;
      margin: 0 40px;
    }
    .step-item {
      display: flex;
      align-items: center;
      .step-name {
        font-size: 16px;
        color: #0a1119;
      }
      .step-num {
        width: 24px;
        height: 24px;
        background: #e4e4e4;
        text-align: center;
        color: #ffffff;
        line-height: 24px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
    .step-on {
      .step-num {
        background-color: #1680ec;
      }
    }
    .line-on {
      background-color: #1680ec;
    }
  }
}
</style>
